import "./styles.scss"

import React, { useEffect, useRef, useState } from "react"
import { motion, useInView } from "framer-motion"

import logo from "assets/images/sky/logo-animated-stii.gif"

const AnimatedLogo = ({ id = "default", isLarge }) => {
  const ref = useRef(null)

  const isInView = useInView(ref, {
    amount: "all",
  })

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isInView) {
      setAnimate(true)
    }
  }, [isInView])

  return (
    <span
      className={`sky-animated-logo${
        isLarge ? " sky-animated-logo--large" : ""
      }`}
      viewport={{
        once: true,
        amount: "all",
      }}
      ref={ref}
    >
      {animate && (
        <motion.img
          src={`${logo}?id=${id}`}
          alt="Sky Trust"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "tween", duration: 0.2 }}
        />
      )}
    </span>
  )
}

export default AnimatedLogo
