import "./styles.scss"

import React, { useRef } from "react"
import { useScroll } from "framer-motion"

import StringIllumination from "components/StringIllumination"

const About = () => {
  const quoteRef = useRef(null)

  const { scrollYProgress: quoteProgress } = useScroll({
    target: quoteRef,
    offset: ["start 0.7", "end"],
  })

  return (
    <>
      <span id="about" className="anchor anchor--small" />
      <section className="sky-about">
        <div className="sky-about__quote" ref={quoteRef}>
          <div className="sky-about__quote-inner">
            <div className="container-sky">
              <div className="sky-label">
                <span>/</span> Emanacja potencjału
              </div>
              <div className="sky-about__quote-wrapper">
                <h4>o sky trust</h4>
                <div className="sky-about__quote-content">
                  <StringIllumination
                    string="Projektując Sky Trust zależało nam na tym, aby inwestycja ta była esencją wielkomiejskich aspiracji Kielc. Jednocześnie wiedzieliśmy, że musi być ona harmonijnie wkomponowana w spokojną aurę kieleckiej codzienności i lokalnych potrzeb funkcjonalnych miejskiej przestrzeni. Już dziś wiemy, że udało nam się to osiągnąć, a Sky Trust to prawdziwie nowa jakość i emanacja potencjału drzemiącego w Kielcach."
                    progress={quoteProgress}
                    colors={{
                      start: "#808d94",
                      end: "#1b2a32",
                    }}
                  />
                </div>
                <p className="sky-about__quote-position">
                  Zespół Trust Investment
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sky-about__author">
        <div className="container-sky">
          <div className="row">
            <div className="col-xl-4 col-md-6 offset-xl-1">
              <div className="sky-about__author-image">
                <img
                  src={require("assets/images/sky/about-boss.jpg").default}
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <div className="sky-about__author-inner">
                <h2>
                  PATRYK
                  <br /> <strong>BARUCHA</strong>
                </h2>
                <p>
                  Prezes Zarządu Trust Investment SA, firmy deweloperskiej
                  realizującej inwestycję Sky Trust oraz wiele innych projektów
                  na terenie całej Polski. Myślą przewodnią każdego
                  realizowanego przez Trust Investment projektu jest człowiek,
                  jego realne potrzeby i oczekiwania oraz podnoszenie standardu
                  życia ludzi w całej Polsce. Nie tylko w największych
                  aglomeracjach, ale również w mniejszych miastach.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </section>
    </>
  )
}

export default About
