const SKY_GALLERY_DATA = [
  {
    category: "Na zewnątrz",
    catalog: "zewnatrz",
    images_small: [
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_1_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_2_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_3_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_4_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_5_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_6_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_7_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_8_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_9_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_10_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_11_small.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_12_small.jpg`).default,
    ],
    images_url: [
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_1.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_2.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_3.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_4.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_5.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_6.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_7.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_8.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_9.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_10.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_11.jpg`).default,
      require(`assets/images/sky/zewnatrz/STII_zewnatrz_12.jpg`).default,
    ],
  },
  {
    category: "Wnętrza",
    catalog: "wnetrze",
    images_small: [
      require(`assets/images/sky/wnetrze/STII_wnetrze_1_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_2_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_3_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_4_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_5_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_6_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_7_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_8_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_9_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_10_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_11_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_12_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_13_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_14_small.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_15_small.jpg`).default,
    ],
    images_url: [
      require(`assets/images/sky/wnetrze/STII_wnetrze_1.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_2.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_3.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_4.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_5.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_6.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_7.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_8.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_9.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_10.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_11.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_12.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_13.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_14.jpg`).default,
      require(`assets/images/sky/wnetrze/STII_wnetrze_15.jpg`).default,
    ],
  },
  {
    category: "Części wspólne",
    catalog: "czesci_wspolne",
    images_small: [
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_1_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_2_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_3_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_4_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_5_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_6_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_7_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_8_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_9_small.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_10_small.jpg`)
        .default,
    ],
    images_url: [
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_1.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_2.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_3.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_4.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_5.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_6.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_7.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_8.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_9.jpg`)
        .default,
      require(`assets/images/sky/czesci_wspolne/STII_czesc_wspolna_10.jpg`)
        .default,
    ],
  },
]

export { SKY_GALLERY_DATA }
