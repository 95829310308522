import "./styles.scss"

import React, { useRef } from "react"
import {
  useMotionTemplate,
  useScroll,
  useTransform,
  motion,
} from "framer-motion"

import { SkewShape } from "../components"

const animation = {
  initial: {
    y: 48,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
  viewport: { once: true },
  transition: { type: "tween", duration: 0.6, ease: "easeInOut" },
}

const Interior = () => {
  const ref = useRef(null)
  const wrapperRef = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })

  const { scrollYProgress: progress_2 } = useScroll({
    target: wrapperRef,
    offset: ["start end", "end"],
  })

  const timing = [0, 1]

  const shapes_x = useTransform(progress, timing, [0, 37.5])
  const shapes_y = useTransform(progress, timing, [50, 0])
  const shapes_transform = useMotionTemplate`translate(${shapes_x}%, ${shapes_y}%)`

  const heading_left_x = useTransform(progress_2, timing, [-75, 0])
  const heading_left_transform = useMotionTemplate`translate(${heading_left_x}%, 0)`
  const heading_right_x = useTransform(progress_2, timing, [75, 0])
  const heading_right_transform = useMotionTemplate`translate(${heading_right_x}%, 0)`

  const polygon_x = useTransform(progress, timing, [-25, 0])
  const polygon_y = useTransform(progress, timing, [37.5, 0])
  const polygon_transform = useMotionTemplate`translate(${polygon_x}%, ${polygon_y}%)`

  return (
    <section className="sky-interior" ref={ref}>
      <div className="sky-interior__wrapper" ref={wrapperRef}>
        <div className="container-sky">
          <motion.div {...animation}>
            <div className="sky-label">
              <span>/</span> ŚWIATOWE WNĘTRZA
            </div>
            <h2>
              WNĘTRZA BY <strong>PAPROCKI BRZOZOWSKI</strong>
            </h2>
            <div className="sky-interior__description">
              Za projekt wnętrz inwestycji odpowiada ceniony duet kreatorów
              artystycznych Paprocki Brzozowski, który wraz z zespołem Trust
              Investment opracował zjawiskowe koncepcje dla każdego z trzech
              składających się na Sky Trust budynków. Części wspólne nakreślone
              przez duet Paprocki Brzozowski są spójne z całościową koncepcją
              Sky Trust, która niesie ze sobą odważną nowoczesność, poczucie
              nienachalnego blichtru i nieskrępowaną pewność siebie.
            </div>
          </motion.div>

          <div className="sky-interior__heading">
            <span>
              <motion.span style={{ transform: heading_left_transform }}>
                Światowe
              </motion.span>
              <img
                src={
                  require("assets/images/sky/logo-paprocki-brzozowski.svg")
                    .default
                }
                alt=""
              />
            </span>
            <span>
              <motion.span style={{ transform: heading_right_transform }}>
                wnętrza
              </motion.span>
            </span>
          </div>
        </div>
      </div>

      <div className="sky-interior__images">
        <div className="container-sky">
          <div className="row align-items-end">
            <motion.div
              className="col-md-6 sky-interior__images-column"
              {...animation}
            >
              <div className="sky-interior__images-image">
                <img
                  src={
                    require("assets/images/sky/interior-image-1.jpg").default
                  }
                  alt=""
                />
              </div>
              <h5>Lobby</h5>
            </motion.div>

            <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 sky-interior__images-column">
              <motion.div className="sky-interior__images-item" {...animation}>
                <div className="sky-interior__images-image">
                  <img
                    src={
                      require("assets/images/sky/interior-image-2.jpg").default
                    }
                    alt=""
                  />
                </div>
                <h5>Wejście</h5>
              </motion.div>
            </div>
          </div>
        </div>

        <motion.div
          className="sky-interior__shapes"
          style={{ transform: shapes_transform }}
        >
          <SkewShape />
        </motion.div>

        <motion.div
          className="sky-interior__polygon"
          style={{ transform: polygon_transform }}
        >
          <div className="sky-interior__polygon-inner" />
        </motion.div>
      </div>
    </section>
  )
}

export default Interior
