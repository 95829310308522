import "./styles.scss"

import React, { useRef } from "react"
import {
  useScroll,
  motion,
  useTransform,
  useMotionTemplate,
} from "framer-motion"

import { SKY_HERO_DATA } from "../../data"

import { AnimatedLogo } from "../../../components"

const Mobile = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end"],
  })

  const timing = [0, 1]

  const image_cp_top = useTransform(progress, timing, [4, 0])
  const image_cp_right = useTransform(progress, timing, [90, 100])
  const image_cp_bottom = useTransform(progress, timing, [96, 100])
  const image_cp_left = useTransform(progress, timing, [10, 0])
  const image_cp_transform = useMotionTemplate`polygon(${image_cp_right}% ${image_cp_top}%, ${image_cp_right}% ${image_cp_bottom}%, ${image_cp_left}% ${image_cp_bottom}%,  ${image_cp_left}% ${image_cp_top}%)`

  const image_opacity = useTransform(progress, timing, [0, 1])

  return (
    <div className="sky-hero-mobile" ref={ref}>
      <div className="sky-hero-mobile__investment">
        <div className="container-fluid">
          <div className="sky-hero-mobile__investment-logo">
            <AnimatedLogo />
          </div>
          <h4>{SKY_HERO_DATA.investment.stage}</h4>
        </div>
      </div>
      <div className="sky-hero-mobile__heading">
        <div className="sky-hero-mobile__heading-item sky-hero-mobile__heading-item--left">
          {SKY_HERO_DATA.heading.left}
        </div>
        <div className="sky-hero-mobile__heading-item sky-hero-mobile__heading-item--right">
          {SKY_HERO_DATA.heading.right}
        </div>
      </div>
      <div className="sky-hero-mobile__image">
        <div className="sky-hero-mobile__image-inner">
          <div className="sky-hero-mobile__image-sticky">
            <div className="sky-hero-mobile__image-wrapper">
              <motion.img
                src={SKY_HERO_DATA.background.mobile.day}
                alt="Atelier II - dzień"
                style={{ clipPath: image_cp_transform }}
              />
              <motion.img
                src={SKY_HERO_DATA.background.mobile.night}
                alt="Atelier II - noc"
                style={{ opacity: image_opacity, clipPath: image_cp_transform }}
              />
            </div>
            <div className="container-fluid">
              <div className="sky-hero-mobile__image-caption">
                <h5>
                  {SKY_HERO_DATA.caption.name}
                  <br />
                  {SKY_HERO_DATA.caption.location}
                </h5>
                <small>{SKY_HERO_DATA.captions[1].progress}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mobile
