/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { Fade } from "react-awesome-reveal"
import Lightbox from "react-image-lightbox"

import { SKY_GALLERY_DATA } from "./data"

const Hero = () => {
  const [cat, setCat] = useState("zewnatrz")
  const [gallery, setGallery] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  useEffect(() => {
    setGallery(
      ...SKY_GALLERY_DATA?.filter(item => item?.catalog === cat).map(item => {
        return item?.images_url
      })
    )
  }, [cat])

  return (
    <>
      <span id="gallery" className="anchor anchor--small" />
      <section className="sky-gallery">
        <div className="container-sky">
          <div className="sky-label">
            <span>/</span> Zobacz
          </div>

          <div className="sky-gallery__row">
            <h2>Galeria</h2>
            <div>
              {SKY_GALLERY_DATA?.map((item, index) => (
                <button
                  key={index}
                  className={item?.catalog === cat ? "current" : ""}
                  onClick={() => setCat(item?.catalog)}
                >
                  {item?.category}
                </button>
              ))}
            </div>
          </div>

          {SKY_GALLERY_DATA?.filter(item => item?.catalog === cat)?.map(
            (item, index) => (
              <div key={index}>
                <Slider {...slider}>
                  {item?.images_small?.map((node, i) => (
                    <div key={i}>
                      <Fade>
                        <button
                          onClick={e => {
                            e.preventDefault()
                            setIsLightboxOpen(true)
                            setPhotoIndex(i)
                          }}
                        >
                          <img src={node} alt="" />
                        </button>
                      </Fade>
                    </div>
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>

        <div className="sky-gallery__polygon">
          <div className="sky-gallery__polygon-inner" />
        </div>

        {isLightboxOpen && (
          <Lightbox
            mainSrc={gallery[photoIndex]}
            nextSrc={gallery[(photoIndex + 1) % gallery.length]}
            prevSrc={
              gallery[(photoIndex + gallery.length - 1) % gallery.length]
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % gallery.length)
            }
          />
        )}
      </section>
    </>
  )
}

export default Hero
