import "./styles.scss"

import React, { useRef } from "react"
import {
  useMotionTemplate,
  useScroll,
  motion,
  useTransform,
} from "framer-motion"

import { AnimatedLogo } from "../../../components"

import { SKY_HERO_DATA } from "../../data"

const Wide = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end"],
  })

  const timing = [0, 1]
  const timing_prehalf = [0, 0.5]
  const timing_posthalf = [0.5, 1]

  const investment_y = useTransform(progress, timing_prehalf, [0, -100])
  const investment_y_transform = useMotionTemplate`translate(0, ${investment_y}%)`

  const heading_left_y = useTransform(progress, timing, [0, 100])
  const heading_left_y_transform = useMotionTemplate`translate(0, ${heading_left_y}%)`
  const heading_right_y = useTransform(progress, timing, [0, -100])
  const heading_right_y_transform = useMotionTemplate`translate(0, ${heading_right_y}%)`

  const image_cp_top = useTransform(progress, timing, [8, 0])
  const image_cp_right = useTransform(progress, timing, [64.16666667, 100])
  const image_cp_bottom = useTransform(progress, timing, [92, 100])
  const image_cp_left = useTransform(progress, timing, [35.83333333, 0])
  const image_cp_transform = useMotionTemplate`polygon(${image_cp_right}% ${image_cp_top}%, ${image_cp_right}% ${image_cp_bottom}%, ${image_cp_left}% ${image_cp_bottom}%,  ${image_cp_left}% ${image_cp_top}%)`

  const image_top = useTransform(progress, timing, [-5, 0])
  const image_top_transform = useMotionTemplate`${image_top}%`
  const image_left = useTransform(progress, timing, [-2, 0])
  const image_left_transform = useMotionTemplate`${image_left}%`
  const image_opacity = useTransform(progress, timing, [0, 1])

  const caption_y = useTransform(progress, timing_prehalf, [0, 100])
  const caption_y_transform = useMotionTemplate`translate(0, ${caption_y}%)`

  const captions_y = useTransform(progress, timing_posthalf, [100, 0])
  const captions_y_transform = useMotionTemplate`translate(0, ${captions_y}%)`

  return (
    <div className="sky-hero-wide" ref={ref}>
      <motion.div
        className="sky-hero-wide__wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.6 }}
      >
        <div className="sky-hero-wide__wrapper-inner">
          <motion.div
            className="sky-hero-wide__investment"
            style={{
              transform: investment_y_transform,
            }}
          >
            <div className="container-fluid">
              <div className="sky-hero-wide__investment-logo">
                <AnimatedLogo/>
              </div>
              <h4>{SKY_HERO_DATA.investment.stage}</h4>
            </div>
          </motion.div>
          <div className="sky-hero-wide__heading">
            <div className="sky-hero-wide__heading-row">
              <motion.div
                className="sky-hero-wide__heading-item sky-hero-wide__heading-item--left"
                style={{ transform: heading_left_y_transform }}
              >
                <div>{SKY_HERO_DATA.heading.left}</div>
              </motion.div>
              <motion.div
                className="sky-hero-wide__heading-item sky-hero-wide__heading-item--right"
                style={{ transform: heading_right_y_transform }}
              >
                <div>{SKY_HERO_DATA.heading.right}</div>
              </motion.div>
            </div>
          </div>
          <motion.div
            className="sky-hero-wide__image"
            style={{ clipPath: image_cp_transform }}
          >
            <motion.div
              className="sky-hero-wide__image-inner sky-hero-wide__image-inner--first"
              style={{
                top: image_top_transform,
                left: image_left_transform,
                backgroundImage: `url(${SKY_HERO_DATA.background.desktop.day})`,
              }}
            />
            <motion.div
              className="sky-hero-wide__image-inner sky-hero-wide__image-inner--second"
              style={{
                top: image_top_transform,
                left: image_left_transform,
                backgroundImage: `url(${SKY_HERO_DATA.background.desktop.night})`,
                opacity: image_opacity,
              }}
            />
          </motion.div>
          <motion.div
            className="sky-hero-wide__caption"
            style={{ transform: caption_y_transform }}
          >
            <div className="sky-hero-wide__caption-inner">
              <p>{SKY_HERO_DATA.caption.location}</p>
              <p>{SKY_HERO_DATA.caption.name}</p>
            </div>
          </motion.div>
          <div className="sky-hero-wide__captions">
            <motion.div
              className="sky-hero-wide__captions-inner"
              style={{ transform: captions_y_transform }}
            >
              <div className="sky-hero-wide__captions-row">
                {SKY_HERO_DATA.captions.map((item, index) => (
                  <div key={index}>
                    <div>
                      <h5>{item.name}</h5>
                      <small>{item.progress}</small>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Wide
