/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React, { useRef, useState } from "react"
import {
  motion,
  useScroll,
  useTransform,
  useMotionTemplate,
} from "framer-motion"

import { SkewShape } from "../components"
import StringLerp from "components/StringLerp"

import IcoPlay from "assets/icons/play-sky.svg"
import IcoSound from "assets/icons/sound-sky.svg"
import IcoMute from "assets/icons/mute-sky.svg"

const Video = () => {
  const [play, setPlay] = useState(false)
  const [mute, setMute] = useState(true)

  const ref = useRef(null)
  const headingRef = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })

  const { scrollYProgress: headingProgress } = useScroll({
    target: headingRef,
    offset: ["start end", "end center"],
  })

  const timing = [0, 1]
  const polygon_x = useTransform(progress, timing, [-25, 12.5])
  const polygon_y = useTransform(progress, timing, [25, -25])
  const polygon_transform = useMotionTemplate`translate(${polygon_x}%, ${polygon_y}%)`

  const shapes_x = useTransform(progress, timing, [0, 37.5])
  const shapes_y = useTransform(progress, timing, [50, 0])
  const shapes_transform = useMotionTemplate`translate(${shapes_x}%, ${shapes_y}%)`

  return (
    <section className="sky-video" ref={ref}>
      <div className="container-sky">
        <div className="sky-label">
          <span>/</span> O Atelier II
        </div>
        <h2 ref={headingRef}>
          <StringLerp
            string="Sky Trust to ikona zrównoważonych projektów urbanistycznych. To miejsce, które zaopiekuje potrzeby jego mieszkańców, lokalnego biznesu, ale też wszystkich Kielczan. To najbardziej wyczekiwana inwestycja w województwie świętokrzyskim, która zmieni oblicze Kielc. Nowa jakość i przyszłość zaczynają się w Sky Trust."
            strong={[
              "To miejsce, które zaopiekuje",
              "jego",
              "lokalnego",
              "ale też",
              "To najbardziej wyczekiwana",
              "w województwie świętokrzyskim",
            ]}
            progress={headingProgress}
            isOpacity
          />
        </h2>
      </div>

      <div className="sky-video__wrapper">
        <motion.div
          className="sky-video__shapes"
          style={{ transform: shapes_transform }}
        >
          <SkewShape />
        </motion.div>

        <img
          src={require("assets/images/sky/video-poster.jpg").default}
          alt=""
        />

        {!play && (
          <button className="sky-video__play" onClick={() => setPlay(true)}>
            <img src={IcoPlay} alt="" />
          </button>
        )}
        <button className="sky-video__sound" onClick={() => setMute(!mute)}>
          <img src={mute ? IcoMute : IcoSound} alt="" />
        </button>
      </div>

      <motion.div
        className="sky-video__polygon sky-video__polygon--1"
        initial={{ y: "25%", opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        viewport={{ once: true }}
        transition={{ type: "tween", duration: 0.6, ease: "easeInOut" }}
      >
        <div className="sky-video__polygon-inner" />
      </motion.div>

      <motion.div
        className="sky-video__polygon sky-video__polygon--2"
        style={{ transform: polygon_transform }}
      >
        <div className="sky-video__polygon-inner" />
      </motion.div>
    </section>
  )
}

export default Video
