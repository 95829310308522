import "./styles.scss"

import React, { useState, useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from "framer-motion"

import { SkewShape } from "../components"
import AnimatedMask from "components/AnimatedMask"

import { animationMobile, transition, variants } from "./animations"
import { data, getSingleData } from "./data"

const Quality = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start center", "end start"],
  })

  const timing = [0, 1]

  const shapes_x = useTransform(progress, timing, [0, -37.5])
  const shapes_y = useTransform(progress, timing, [-37.5, 12.5])
  const shapes_transform = useMotionTemplate`translate(${shapes_x}%, ${shapes_y}%)`

  const polygon_x = useTransform(progress, timing, [-12.5, 12.5])
  const polygon_y = useTransform(progress, timing, [12.5, -25])
  const polygon_transform = useMotionTemplate`translate(${polygon_x}%, ${polygon_y}%)`

  const [animate, setAnimate] = useState({
    image_1: false,
    image_2: false,
    image_3: false,
    image_4: false,
    image_5: false,
  })

  const location = getSingleData("topowa-lokalizacja")
  const building = getSingleData("najwyzszy-budynek")
  const author = getSingleData("by-paprocki-brzozowski")
  const bolt = getSingleData("blue-bolt")
  const locker = getSingleData("prywatne-paczkomaty")

  return (
    <section className="sky-quality" ref={ref}>
      <div className="container-sky">
        <h2>Nowa jakość w Kielcach</h2>

        <div className="sky-quality__group sky-quality__group--desktop">
          <div className="row">
            <div className="col-xl-7">
              <div className="sky-quality__wrapper sky-quality__wrapper--1">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <motion.div
                      className="sky-quality__item"
                      variants={variants()}
                      initial="hidden"
                      animate={animate.image_1 ? "visible" : "hidden"}
                      transition={transition}
                    >
                      <h3>{location.title}</h3>
                      <strong>{location.subtitle}</strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: location.description,
                        }}
                      />
                    </motion.div>
                  </div>
                  <div className="col-md-7 sky-quality__image-column">
                    <div className="sky-quality__image">
                      <img src={location.image} alt="" />
                      <AnimatedMask
                        variant="dark"
                        callback={() =>
                          setAnimate(prevAnimate => ({
                            ...prevAnimate,
                            image_1: true,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sky-quality__wrapper sky-quality__wrapper--2">
                <motion.div
                  className="sky-quality__item"
                  variants={variants()}
                  initial="hidden"
                  animate={animate.image_2 ? "visible" : "hidden"}
                  transition={transition}
                >
                  <h3>{building.title}</h3>
                  <strong>{building.subtitle}</strong>
                  <div
                    dangerouslySetInnerHTML={{ __html: building.description }}
                  />
                </motion.div>
              </div>
            </div>

            <div className="col-xlg-4 offset-xlg-1 col-md-5 sky-quality__image-column">
              <div className="sky-quality__image sky-quality__image--2">
                <img src={building.image} alt="" />
                <AnimatedMask
                  variant="dark"
                  callback={() =>
                    setAnimate(prevAnimate => ({
                      ...prevAnimate,
                      image_2: true,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className="sky-quality__wrapper sky-quality__wrapper--3">
            <div className="row align-items-center">
              <div className="col-md-7 sky-quality__image-column">
                <div className="sky-quality__image">
                  <img src={author.image} alt="" />
                  <AnimatedMask
                    variant="dark"
                    callback={() =>
                      setAnimate(prevAnimate => ({
                        ...prevAnimate,
                        image_3: true,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-5 offset-lg-1">
                <motion.div
                  className="sky-quality__item"
                  variants={variants(true)}
                  initial="hidden"
                  animate={animate.image_3 ? "visible" : "hidden"}
                  transition={transition}
                >
                  <h3>{author.title}</h3>
                  <strong>{author.subtitle}</strong>
                  <div
                    dangerouslySetInnerHTML={{ __html: author.description }}
                  />
                </motion.div>
              </div>
            </div>
          </div>

          <div className="sky-quality__wrapper sky-quality__wrapper--4">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 offset-lg-1">
                <motion.div
                  className="sky-quality__item"
                  variants={variants()}
                  initial="hidden"
                  animate={animate.image_4 ? "visible" : "hidden"}
                  transition={transition}
                >
                  <h3>{bolt.title}</h3>
                  <strong></strong>
                  <div dangerouslySetInnerHTML={{ __html: bolt.description }} />
                </motion.div>
              </div>
              <div className="col-lg-6 col-md-7 sky-quality__image-column">
                <div className="sky-quality__image">
                  <img src={bolt.image} alt="" />
                  <AnimatedMask
                    variant="dark"
                    callback={() =>
                      setAnimate(prevAnimate => ({
                        ...prevAnimate,
                        image_4: true,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sky-quality__wrapper sky-quality__wrapper--5">
            <div className="row align-items-center">
              <div className="col-md-7 sky-quality__image-column">
                <div className="sky-quality__image">
                  <img src={locker.image} alt="" />
                  <AnimatedMask
                    variant="dark"
                    callback={() =>
                      setAnimate(prevAnimate => ({
                        ...prevAnimate,
                        image_5: true,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-5 offset-lg-1">
                <motion.div
                  className="sky-quality__item"
                  variants={variants(true)}
                  initial="hidden"
                  animate={animate.image_5 ? "visible" : "hidden"}
                  transition={transition}
                >
                  <h3>{locker.title}</h3>
                  <strong>{locker.subtitle}</strong>
                  <div
                    dangerouslySetInnerHTML={{ __html: locker.description }}
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div className="sky-quality__group sky-quality__group--mobile">
          {data.map(item => (
            <motion.div
              className="sky-quality__block"
              key={item.slug}
              {...animationMobile}
            >
              <div
                className="sky-quality__block-image"
                style={{ backgroundImage: `url(${item.image})` }}
              />
              <h3>{item.title}</h3>
              <strong>{item.subtitle}</strong>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </motion.div>
          ))}
        </div>
      </div>

      <motion.div
        className="sky-quality__shapes"
        style={{ transform: shapes_transform }}
      >
        <SkewShape />
      </motion.div>

      <motion.div
        className="sky-quality__polygon"
        style={{ transform: polygon_transform }}
      >
        <div className="sky-quality__polygon-inner" />
      </motion.div>
    </section>
  )
}

export default Quality
