import "./styles.scss"

import React from "react"
import scrollToElement from "scroll-to-element"

const CallToAction = () => {
  return (
    <section className="sky-cta sky-cta--hidden">
      <button onClick={() => scrollToElement("#contact")}>
        Zapytaj o apartament
      </button>
    </section>
  )
}

export default CallToAction
