import "./styles.scss"

import React, { useState } from "react"
import { motion } from "framer-motion"

import { AnimatedLogo, Form } from "../components"
import AnimatedMask from "components/AnimatedMask"

const variants = {
  hidden: {
    y: 32,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const getTransition = delay => ({
  type: "tween",
  ease: "easeInOut",
  duration: 0.4,
  delay,
})

const Contact = () => {
  const [animate, setAnimate] = useState(false)

  return (
    <>
      <span id="contact" className="anchor anchor--small" />
      <section className="sky-contact">
        <div className="container-sky">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div
                className="sky-contact__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/sky/contact-image.jpg").default
                  }')`,
                }}
              >
                <AnimatedMask
                  variant="dark"
                  callback={() => setAnimate(true)}
                />
              </div>
            </div>

            <div className="col-md-6 offset-lg-1">
              <div className="sky-contact__inner">
                <div className="sky-contact__logo">
                  <AnimatedLogo id="footer" />
                </div>
                <motion.div
                  className="sky-label"
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.2)}
                >
                  <span>/</span> kontakt
                </motion.div>
                <motion.h2
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.4)}
                >
                  Zapytaj o Apartamenty <strong>Atelier w Sky Trust</strong>
                </motion.h2>
                <motion.div
                  className="sky-contact__form"
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.6)}
                >
                  <Form />
                </motion.div>
                <motion.div
                  className="sky-contact__shapes"
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.8)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
