import "./styles.scss"

import React from "react"
import { Helmet } from "react-helmet"

const Apartments = () => {
  return (
    <section className="sky-apartments">
      <Helmet>
        <script
          src="https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/js/main.js"
          onLoad={`(function(){SM.init('${"4c1d85d3-eeae-416a-847b-ff339dbd7917"}', 'smart-makieta', { '--color-main': '#ac875c' }, {showPrice: true, autoscrollDesktop: true})})()`}
        />
      </Helmet>

      <div className="container-sky">
        <div className="sky-label">
          <span>/</span> Twoje miejsce
        </div>
        <h2>Apartamenty</h2>
      </div>

      <span id="apartments" className="anchor anchor--small" />

      <div id="smart-makieta"></div>
    </section>
  )
}

export default Apartments
