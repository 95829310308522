import "./styles.scss"

import React, { Fragment, useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useTransform,
  useScroll,
} from "framer-motion"
import scrollToElement from "scroll-to-element"

const Baner = ({ spacerRef }) => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })
  const timing = [0, 1]

  const shapes_y = useTransform(progress, timing, [-2.5, 2.5])
  const shapes_transform = useMotionTemplate`translate(${shapes_y}%, 0)`
  const shapes_reverse_y = useTransform(progress, timing, [2.5, -2.5])
  const shapes_reverse_transform = useMotionTemplate`translate(${shapes_reverse_y}%, 0)`

  return (
    <section className="sky-baner" ref={ref}>
      <div className="sky-baner__wrapper">
        <button
          className="sky-baner__cta"
          onClick={() => scrollToElement("#contact")}
        >
          Porozmawiajmy
        </button>

        <div className="sky-baner__shapes">
          <div className="sky-baner__shapes-inner">
            {new Array(4).fill().map((_, index) => (
              <Fragment key={index}>
                <motion.div
                  className="sky-baner__shapes-item"
                  style={{ transform: shapes_transform }}
                />
                <motion.div
                  className="sky-baner__shapes-item sky-baner__shapes-item--reverse"
                  style={{ transform: shapes_reverse_transform }}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="sky-baner__spacer">
        <div className="sky-baner__spacer-inner" ref={spacerRef} />
      </div>
    </section>
  )
}

export default Baner
