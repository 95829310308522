import React from "react"

import CursorProvider from "context/cursor"

import Layout from "components/Layout"

import {
  Hero,
  Discover,
  Apartments,
  Entertainment,
  Quality,
  Transition,
  SliderLocation,
  Interior,
  About,
  Contact,
  CallToAction,
  Gallery,
} from "page_components/sky-landing"

const Investment = ({ location }) => {
  const title = "Sky Trust II"

  return (
    <CursorProvider>
      <Layout
        location={location}
        rmGlobalPopup
        mainClass="sky-landing"
        seo={{
          title: title,
        }}
      >
        <Hero />
        <Discover />
        <Apartments />
        <Entertainment />
        <Quality />
        <Transition />
        <SliderLocation />
        <Interior />
        <Gallery />
        <About />
        <Contact />
        <CallToAction />
      </Layout>
    </CursorProvider>
  )
}

export default Investment
