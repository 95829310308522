const data = [
  {
    slug: "topowa-lokalizacja",
    image: require("assets/images/sky/quality-image-1.jpg").default,
    title: "Topowa",
    subtitle: "lokalizacja",
    description:
      "<p>Zawsze w centrum wydarzeń. Galeria Echo, Rynek, ul. Sienkiewicza, szkoły, uczelnie, tereny rekreacyjne, komunikacja miejska - wszystko co ważne na wyciągnięcie ręki. Bliskość węzła Kraków - Kielce - Warszawa zapewnia szybkie połączenie z całą Polską.</p>",
  },
  {
    slug: "najwyzszy-budynek",
    image: require("assets/images/sky/quality-image-2.jpg").default,
    title: "Najwyższy budynek",
    subtitle: "w regionie",
    description:
      "<p>Sky Trust to odważny krok w kierunku Kielc przyszłości. Najwyższe w województwie, przeszklone wieżowce z zapierającym dech w piersiach widokiem na panoramę miasta.</p>",
  },
  {
    slug: "by-paprocki-brzozowski",
    image: require("assets/images/sky/quality-image-3.jpg").default,
    title: "By Paprocki Brzozowski",
    subtitle: "Projekt wnętrz",
    description:
      "<p>Za projekt wnętrz inwestycji odpowiada duet uznanych kreatorów mody Paprocki Brzozowski nadając każdemy z trzech budynków unikalnego, światowego charakteru.</p>",
  },
  {
    slug: "blue-bolt",
    image: require("assets/images/sky/quality-image-4.jpg").default,
    title: "Blue Bolt",
    subtitle: null,
    description:
      "<p>Użyteczność na co dzień. Funkcje dostępne z poziomu aplikacji w telefonie:</p><ul><li>przywoływanie windy,</li><li>otwieranie drzwi,</li><li>otwieranie hali garażowej.</li></ul>",
  },
  {
    slug: "prywatne-paczkomaty",
    image: require("assets/images/sky/quality-image-5.jpg").default,
    title: "Prywatne",
    subtitle: "paczkomaty",
    description:
      "<p>Wygoda ponad wszystko. Brak konieczności oczekiwania na kuriera czy wizyty w punkcie odbioru.</p>",
  },
]

const getSingleData = slug => data.filter(item => item.slug === slug)[0]

export { data, getSingleData }
