import "./styles.scss"

import React, { useState, useRef } from "react"
import Slider from "react-slick"
import {
  useScroll,
  motion,
  useTransform,
  useMotionTemplate,
} from "framer-motion"

import AnimatedMask from "components/AnimatedMask"

const variantsDescriptionLeft = {
  hidden: { y: "25%", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const variantsDescriptionRight = {
  hidden: { y: "-25%", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const transition = {
  type: "tween",
  ease: [0.72, 0.15, 0.34, 0.86],
  duration: 0.8,
}

const slides = [
  require("assets/images/sky/location-content-image-1.jpg").default,
  require("assets/images/sky/location-content-image-2.jpg").default,
  require("assets/images/sky/location-content-image-3.jpg").default,
  require("assets/images/sky/location-content-image-4.jpg").default,
]

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  draggable: false,
  touchMove: false,
  speed: 1000,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  puaseOnFocus: false,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Location = () => {
  const ref = useRef(null)
  const sliderRef = useRef(null)

  const [animate, setAnimate] = useState(false)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })

  const polygon_x = useTransform(progress, [0, 1], [25, 0])
  const polygon_y = useTransform(progress, [0, 1], [0, 37.5])
  const polygon_transform = useMotionTemplate`translate(${polygon_x}%, ${polygon_y}%)`

  return (
    <>
      <span id="location" className="anchor anchor--small" />
      <section className="sky-location" ref={ref}>
        <div className="container-fluid">
          <div className="sky-label">
            <span>/</span> LOKALIZACJA
          </div>
          <h2>
            ABSOLUTNIE <strong>TOPOWA LOKALIZACJA</strong>
          </h2>

          <div className="row align-items-center">
            <motion.div
              className="col-xl-4"
              variants={variantsDescriptionLeft}
              initial="hidden"
              animate={animate ? "visible" : "hidden"}
              transition={transition}
            >
              <p>
                Dwa kilometry od Rynku, ulicy Sienkiewicza czy Zalewu
                Kieleckiego. 500 metrów od Galerii Echo, Kina Helios, tuż obok
                wydziałów Uniwersytetu Jana Kochanowskiego. Na miejscu sklepy,
                szkoły, komunikacja miejska, a nieopodal węzeł komunikacyjny
                dróg krajowych 73 i 74 Kraków - Kielce - Warszawa. Sky Trust to
                centrum życia lokalnego z fenomenalnym połączeniem z Polską i
                całym światem.
              </p>
            </motion.div>
            <div className="col-xl-5">
              <div className="sky-location__image">
                <Slider {...sliderSettings} ref={sliderRef}>
                  {slides.map((slide, index) => (
                    <div key={index}>
                      <img
                        src={slide}
                        alt={`Zdjęcie położenia Sky Trust ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
                <AnimatedMask
                  callback={() => {
                    sliderRef.current.slickPlay()
                    setAnimate(true)
                  }}
                />
              </div>
            </div>
            <motion.div
              className="col-xl-3"
              variants={variantsDescriptionRight}
              initial="hidden"
              animate={animate ? "visible" : "hidden"}
              transition={transition}
            >
              <p>
                Zgodnie z założeniami strategii Smart City Kielce 2030+, Sky
                Trust zaprojektowano z dbałością o potrzeby mieszkańców, biznesu
                oraz realizując miejskie założenia urbanistyczne. Dzięki temu
                już wkrótce Kielce zyskają nowe, modne i wygodne destination
                place.
              </p>
            </motion.div>
          </div>
        </div>

        <div className="sky-location__map">
          <img
            src={require("assets/images/sky/location-map.svg").default}
            alt=""
          />
        </div>

        <motion.div
          className="sky-location__polygon"
          style={{ transform: polygon_transform }}
        >
          <div className="sky-location__polygon-inner" />
        </motion.div>
      </section>
    </>
  )
}

export default Location
