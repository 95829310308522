import "./styles.scss"

import React from "react"

const SkewShape = () => {
  return (
    <div className="skew-shapes">
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <span key={index} />
        ))}
    </div>
  )
}

export default SkewShape
