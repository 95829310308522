const SKY_HERO_DATA = {
  investment: {
    logo: {
      image: require("assets/images/sky/logo.svg").default,
      alt: "SKY TRUST",
    },
    stage: "ETAP II: ATELIER",
  },
  heading: {
    left: "Sięgnij",
    right: "wyżej",
  },
  background: {
    desktop: {
      day: require("assets/images/sky/hero-buildings-evening.jpg").default,
      night: require("assets/images/sky/hero-buildings-night.jpg").default,
    },
    mobile: {
      day: require("assets/images/sky/hero-buildings-evening--mobile.jpg")
        .default,
      night: require("assets/images/sky/hero-buildings-night--mobile.jpg")
        .default,
    },
  },
  caption: {
    location: "al. Solidarności",
    name: "Kielce",
  },
  captions: [
    {
      name: "Prêt-à-porter",
      progress: "Sprzedane",
    },
    {
      name: "Atelier",
      progress: "W sprzedaży",
    },
    {
      name: "Houte Couture",
      progress: "2026",
    },
  ],
}

export { SKY_HERO_DATA }
