const variants = reverse => ({
  hidden: {
    x: 24 * (reverse ? -1 : 1),
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
})

const transition = {
  type: "tween",
  ease: [0.72, 0.15, 0.34, 0.86],
  duration: 0.8,
}

const animationMobile = {
  initial: {
    y: 48,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
  viewport: { once: true },
  transition: { type: "tween", duration: 0.6, ease: "easeInOut" },
}

export { variants, transition, animationMobile }
