import "./styles.scss"

import React, { useState, useRef } from "react"
import Slider from "react-slick"
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
  useMotionTemplate,
} from "framer-motion"

import { useCursor } from "context/cursor"

const slides = [
  {
    image: require("assets/images/sky/entertainment-gallery-1.jpg").default,
    label: "Prywatny gaming room",
  },
  {
    image: require("assets/images/sky/entertainment-gallery-2.jpg").default,
    label: "Prywatna sala kinowa",
  },
  {
    image: require("assets/images/sky/entertainment-gallery-3.jpg").default,
    label: "Prywatna sala kinowa",
  },
]

const INITIAL_CAPTIONS = slides.map(slide => slide.label)

const captionAnimation = {
  initial: {
    y: "33.3333%",
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: "-33.3333%",
    opacity: 0,
  },
  transition: {
    type: "tween",
    duration: 0.35,
  },
}

const Entertainment = () => {
  const { setCursor } = useCursor()

  const ref = useRef(null)

  const [captions, setCaptions] = useState(INITIAL_CAPTIONS)

  const s1_ref = useRef(null)
  const s2_ref = useRef(null)
  const s3_ref = useRef(null)

  const getSettings = isPrimary => ({
    dots: false,
    arrows: false,
    infinite: true,
    draggable: false,
    touchMove: false,
    speed: 1000,
    autoplay: isPrimary ? true : false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    puaseOnFocus: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      if (isPrimary) {
        const slidesLength = slides.length - 1

        const handleNextCaptions = () => {
          setCaptions(prevCaptions => {
            const current = prevCaptions[0]

            return [...prevCaptions.slice(1), current]
          })
        }

        const handlePrevCaptions = () => {
          setCaptions(prevCaptions => {
            const current = prevCaptions[slidesLength]

            return [current, ...prevCaptions.slice(0, -1)]
          })
        }

        if (oldIndex === 0 && newIndex === slidesLength) {
          s2_ref.current.slickPrev()
          s3_ref.current.slickPrev()

          handlePrevCaptions()
        } else {
          if (
            oldIndex < newIndex ||
            (oldIndex === slidesLength && newIndex === 0)
          ) {
            s2_ref.current.slickNext()
            s3_ref.current.slickNext()

            handleNextCaptions()
          } else {
            s2_ref.current.slickPrev()
            s3_ref.current.slickPrev()

            handlePrevCaptions()
          }
        }
      }
    },
  })

  const getNavProps = (direction = "left", callbackEnter, callbackLeave) => ({
    onMouseEnter: () => {
      if (callbackEnter && typeof callbackEnter === "function") {
        callbackEnter()
      }

      setCursor([true, `arrow-${direction}`])
    },
    onMouseLeave: () => {
      if (callbackLeave && typeof callbackLeave === "function") {
        callbackLeave()
      }

      setCursor([null, `arrow-${direction}`])
    },
    onClick: () =>
      direction === "left"
        ? s1_ref.current.slickPrev()
        : s1_ref.current.slickNext(),
  })

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  })

  const timing = [0, 1]
  const polygon_x = useTransform(progress, timing, [0, -25])
  const polygon_y = useTransform(progress, timing, [-12.5, 25])
  const polygon_transform = useMotionTemplate`translate(${polygon_x}%, ${polygon_y}%)`

  return (
    <section className="sky-entertainment" ref={ref}>
      <div className="container-sky">
        <div className="row">
          <div className="col-lg-8">
            <div className="sky-entertainment__image sky-entertainment__image--1">
              <Slider {...getSettings(true)} ref={s1_ref}>
                {slides.map((slide, index) => (
                  <div key={index} className="sky-entertainment__slide">
                    <img src={slide.image} alt={slide.label} />
                    <div className="sky-entertainment__mask">
                      <button
                        className="sky-entertainment__mask-action sky-entertainment__mask-action--prev"
                        type="button"
                        {...getNavProps(
                          "left",
                          () => s1_ref.current.slickPause(),
                          () => s1_ref.current.slickPlay()
                        )}
                      >
                        Poprzedni/Stop
                      </button>
                      <button
                        className="sky-entertainment__mask-action sky-entertainment__mask-action--next"
                        type="button"
                        {...getNavProps(
                          "right",
                          () => s1_ref.current.slickPause(),
                          () => s1_ref.current.slickPlay()
                        )}
                      >
                        Następny/Stop
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>

              <div className="sky-entertainment__heading">
                <div className="sky-label">
                  <span>/</span> Poznaj
                </div>

                <h2>
                  Zachwycająca <strong>Przestrzeń</strong>
                </h2>
              </div>
              <div className="sky-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5 key={captions[0]} {...captionAnimation}>
                    {captions[0]}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="sky-entertainment__image sky-entertainment__image--2">
              <img
                src={
                  require("assets/images/sky/entertainment-image-4.jpg").default
                }
                alt=""
              />
            </div>
            <div className="sky-entertainment__image sky-entertainment__image--3 sky-entertainment__image--desktop">
              <Slider {...getSettings()} ref={s3_ref}>
                {[...slides.slice(2), slides[0], slides[1]].map(
                  (slide, index) => (
                    <div key={index}>
                      <img src={slide.image} alt={slide.label} />
                    </div>
                  )
                )}
              </Slider>
              <div className="sky-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5 key={captions[2]} {...captionAnimation}>
                    {captions[2]}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="sky-entertainment__image sky-entertainment__image--4 sky-entertainment__image--desktop">
              <Slider {...getSettings()} ref={s2_ref}>
                {[...slides.slice(1), slides[0]].map((slide, index) => (
                  <div key={index}>
                    <img src={slide.image} alt={slide.label} />
                  </div>
                ))}
              </Slider>
              <div className="sky-entertainment__image-caption">
                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.h5 key={captions[1]} {...captionAnimation}>
                    {captions[1]}
                  </motion.h5>
                </AnimatePresence>
              </div>
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1">
            <div className="sky-entertainment__image sky-entertainment__image--5">
              <img
                src={
                  require("assets/images/sky/entertainment-image-5.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sky-entertainment__navigation">
        <button
          className="sky-entertainment__navigation-arrow sky-entertainment__navigation-arrow--prev"
          type="button"
          {...getNavProps()}
        >
          Poprzedni
        </button>
        <button
          className="sky-entertainment__navigation-arrow sky-entertainment__navigation-arrow--next"
          type="button"
          {...getNavProps("right")}
        >
          Następny
        </button>
      </div>

      <motion.div
        className="sky-entertainment__polygon"
        style={{ transform: polygon_transform }}
      >
        <div className="sky-entertainment__polygon-inner" />
      </motion.div>
    </section>
  )
}

export default Entertainment
